import React from "react"
// import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import Footer from "../components/footer"
import Privacy from "../components/privacy"
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery2 {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <Helmet
        // htmlAttributes={}
        title={"Privacy"}
        titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      >
        <meta name="robots" content="noindex" />
      </Helmet>
      <Privacy />
      <Footer />
    </div>
  )
}

export default IndexPage
