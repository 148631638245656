// import { Link } from "gatsby"
import React from "react"

const Privacy = () => {
  return (
    <div className="content">
      <div className="content__back">
        <div className="container">
          <a href="/" title="Back to home">
            Back to home
          </a>
        </div>
      </div>
      <div className="container content__wrapper">
        <h1>Privacy</h1>
        <p>
          Name und Kontakt des Verantwortlichen gem&auml;&szlig; Artikel 4 Abs.
          7 DSGVO
          <br />
          <br />
          <strong>Verantwortlicher:</strong>
          <br />
          <strong>NEU_FUNDLAND Projekte GmbH</strong>
          <br />
          Heiligengeistwall 11
          <br />
          26122 Oldenburg
          <br />
          <br />
          <strong>Vertretungsberechtigte Person:</strong> Konrad L&uuml;ders
          <br />
          <strong>E-Mail-Adresse:</strong>{" "}
          <a href="mailto:hello@hiive.de">hello@hiive.de</a>
          <br />
          <strong>Impressum:</strong>{" "}
          <a href="http://www.hiive.de/privacy">www.hiive.de/privacy</a>
        </p>
        <p>
          <strong>Einleitung:</strong>
          <br />
          Mit der folgenden Datenschutzerkl&auml;rung m&ouml;chten wir Sie
          dar&uuml;ber aufkl&auml;ren, welche Arten Ihrer personenbezogenen
          Daten (nachfolgend auch kurz als "Daten&ldquo; bezeichnet) wir zu
          welchen Zwecken und in welchem Umfang verarbeiten. Die
          Datenschutzerkl&auml;rung gilt f&uuml;r alle von uns
          durchgef&uuml;hrten Verarbeitungen personenbezogener Daten, sowohl im
          Rahmen der Erbringung unserer Leistungen als auch insbesondere auf
          unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
          Onlinepr&auml;senzen, wie z.B. unserer Social-Media-Profile
          (nachfolgend zusammenfassend bezeichnet als "Onlineangebot&ldquo;).
          <br />
          <br />
          Die verwendeten Begriffe sind nicht geschlechtsspezifisch.
          <br />
          Stand: 12. Dezember 2019
          <br />
          <br />
          <strong>Inhalts&uuml;bersicht:</strong>
          <br />
          Einleitung
          <br />
          &Uuml;bersicht der Verarbeitungen
          <br />
          Ma&szlig;gebliche Rechtsgrundlagen
          <br />
          Datenverarbeitung in Drittl&auml;ndern
          <br />
          Kommerzielle und gesch&auml;ftliche Leistungen
          <br />
          Bereitstellung des Onlineangebotes und Webhosting
          <br />
          Onlinemarketing
          <br />
          Pr&auml;senzen in sozialen Netzwerken
          <br />
          Plugins und eingebettete Funktionen sowie Inhalte
          <br />
          L&ouml;schung von Daten
          <br />
          &Auml;nderung und Aktualisierung der Datenschutzerkl&auml;rung
          <br />
          Rechte der betroffenen Personen
          <br />
          <br />
          <strong>&Uuml;bersicht der Verarbeitungen:</strong>
          <br />
          Die nachfolgende &Uuml;bersicht fasst die Arten der verarbeiteten
          Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die
          betroffenen Personen.
          <br />
          Arten der verarbeiteten Daten
          <br />
          Bestandsdaten (z.B. Namen, Adressen).
          <br />
          Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos).
          <br />
          Kontaktdaten (z.B. E-Mail, Telefonnummern).
          <br />
          Meta-/Kommunikationsdaten (z.B. Ger&auml;te-Informationen,
          IP-Adressen).
          <br />
          Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
          Zugriffszeiten).
          <br />
          Standortdaten (Daten, die den Standort des Endger&auml;ts eines
          Endnutzers angeben).
          <br />
          Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).
          <br />
          Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie).
          <br />
          Kategorien betroffener Personen
          <br />
          Gesch&auml;fts- und Vertragspartner.
          <br />
          Interessenten.
          <br />
          Kunden.
          <br />
          Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
          <br />
          Zwecke der Verarbeitung
          <br />
          Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
          <br />
          Besuchsaktionsauswertung.
          <br />
          B&uuml;ro- und Organisationsverfahren.
          <br />
          Cross-Device Tracking (ger&auml;te&uuml;bergreifende Verarbeitung von
          Nutzerdaten f&uuml;r Marketingzwecke).
          <br />
          Interessenbasiertes und verhaltensbezogenes Marketing.
          <br />
          Kontaktanfragen und Kommunikation.
          <br />
          Konversionsmessung (Messung der Effektivit&auml;t von
          Marketingma&szlig;nahmen).
          <br />
          Profiling (Erstellen von Nutzerprofilen).
          <br />
          Remarketing.
          <br />
          Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung
          wiederkehrender Besucher).
          <br />
          Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von
          Cookies).
          <br />
          Servermonitoring und Fehlererkennung.
          <br />
          Vertragliche Leistungen und Service.
          <br />
          Verwaltung und Beantwortung von Anfragen.
          <br />
          Zielgruppenbildung (Bestimmung von f&uuml;r Marketingzwecke relevanten
          Zielgruppen oder sonstige Ausgabe von Inhalten).
          <br />
          <br />
          <strong>Ma&szlig;gebliche Rechtsgrundlagen:</strong>
          <br />
          Im Folgenden teilen wir die Rechtsgrundlagen der
          Datenschutzgrundverordnung (DSGVO), auf deren Basis wir die
          personenbezogenen Daten verarbeiten, mit. Bitte beachten Sie, dass
          zus&auml;tzlich zu den Regelungen der DSGVO die nationalen
          Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und Sitzland gelten
          k&ouml;nnen.
          <br />
          Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO) - Die betroffene Person
          hat ihre Einwilligung in die Verarbeitung der sie betreffenden
          personenbezogenen Daten f&uuml;r einen spezifischen Zweck oder mehrere
          bestimmte Zwecke gegeben.
          <br />
          Vertragserf&uuml;llung und vorvertragliche Anfragen (Art. 6 Abs. 1 S.
          1 lit. b. DSGVO) - Die Verarbeitung ist f&uuml;r die Erf&uuml;llung
          eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder
          zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich,
          die auf Anfrage der betroffenen Person erfolgen.
          <br />
          Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c. DSGVO) - Die
          Verarbeitung ist zur Erf&uuml;llung einer rechtlichen Verpflichtung
          erforderlich, der der Verantwortliche unterliegt.
          <br />
          Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO) - Die
          Verarbeitung ist zur Wahrung der berechtigten Interessen des
          Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
          Interessen oder Grundrechte und Grundfreiheiten der betroffenen
          Person, die den Schutz personenbezogener Daten erfordern,
          &uuml;berwiegen.
          <br />
          Nationale Datenschutzregelungen in Deutschland: Zus&auml;tzlich zu den
          Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale
          Regelungen zum Datenschutz in Deutschland. Hierzu geh&ouml;rt
          insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener
          Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz &ndash;
          BDSG). Das BDSG enth&auml;lt insbesondere Spezialregelungen zum Recht
          auf Auskunft, zum Recht auf L&ouml;schung, zum Widerspruchsrecht, zur
          Verarbeitung besonderer Kategorien personenbezogener Daten, zur
          Verarbeitung f&uuml;r andere Zwecke und zur &Uuml;bermittlung sowie
          automatisierten Entscheidungsfindung im Einzelfall
          einschlie&szlig;lich Profiling. Des Weiteren regelt es die
          Datenverarbeitung f&uuml;r Zwecke des
          Besch&auml;ftigungsverh&auml;ltnisses (&sect; 26 BDSG), insbesondere
          im Hinblick auf die Begr&uuml;ndung, Durchf&uuml;hrung oder Beendigung
          von Besch&auml;ftigungsverh&auml;ltnissen sowie die Einwilligung von
          Besch&auml;ftigten. Ferner k&ouml;nnen Landesdatenschutzgesetze der
          einzelnen Bundesl&auml;nder zur Anwendung gelangen.
          <br />
          <br />
          <strong>Datenverarbeitung in Drittl&auml;ndern:</strong>
          <br />
          Sofern wir Daten in einem Drittland (d.h., au&szlig;erhalb der
          Europ&auml;ischen Union (EU), des Europ&auml;ischen Wirtschaftsraums
          (EWR)) verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme
          von Diensten Dritter oder der Offenlegung bzw. &Uuml;bermittlung von
          Daten an andere Personen, Stellen oder Unternehmen stattfindet,
          erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben. <br />
          Vorbehaltlich ausdr&uuml;cklicher Einwilligung oder vertraglich oder
          gesetzlich erforderlicher &Uuml;bermittlung verarbeiten oder lassen
          wir die Daten nur in Drittl&auml;ndern mit einem anerkannten
          Datenschutzniveau, zu denen die unter dem "Privacy-Shield"
          zertifizierten US-Verarbeiter geh&ouml;ren, oder auf Grundlage
          besonderer Garantien, wie z.B. vertraglicher Verpflichtung durch
          sogenannte Standardschutzklauseln der EU-Kommission, des Vorliegens
          von Zertifizierungen oder verbindlicher interner
          Datenschutzvorschriften, verarbeiten (Art. 44 bis 49 DSGVO,
          Informationsseite der EU-Kommission:
          https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
          ).
          <br />
          <br />
          <strong>Kommerzielle und gesch&auml;ftliche Leistungen:</strong>
          <br />
          Wir verarbeiten Daten unserer Vertrags- und Gesch&auml;ftspartner,
          z.B. Kunden und Interessenten (zusammenfassend bezeichnet als
          "Vertragspartner") im Rahmen von vertraglichen und vergleichbaren
          Rechtsverh&auml;ltnissen sowie damit verbundenen Ma&szlig;nahmen und
          im Rahmen der Kommunikation mit den Vertragspartnern (oder
          vorvertraglich), z.B., um Anfragen zu beantworten.
          <br />
          Diese Daten verarbeiten wir zur Erf&uuml;llung unserer vertraglichen
          Pflichten, zur Sicherung unserer Rechte und zu Zwecken der mit diesen
          Angaben einhergehenden Verwaltungsaufgaben sowie der unternehmerischen
          Organisation. Die Daten der Vertragspartner geben wir im Rahmen des
          geltenden Rechts nur insoweit an Dritte weiter, als dies zu den
          vorgenannten Zwecken oder zur Erf&uuml;llung gesetzlicher Pflichten
          erforderlich ist oder mit Einwilligung der Vertragspartner erfolgt
          (z.B. an beteiligte Telekommunikations-, Transport- und sonstige
          Hilfsdienste sowie Subunternehmer, Banken, Steuer- und Rechtsberater,
          Zahlungsdienstleister oder Steuerbeh&ouml;rden). &Uuml;ber weitere
          Verarbeitungsformen, z.B. zu Zwecken des Marketings, werden die
          Vertragspartner im Rahmen dieser Datenschutzerkl&auml;rung informiert.
          <br />
          Welche Daten f&uuml;r die vorgenannten Zwecke erforderlich sind,
          teilen wir den Vertragspartnern vor oder im Rahmen der Datenerhebung,
          z.B. in Onlineformularen, durch besondere Kennzeichnung (z.B. Farben)
          bzw. Symbole (z.B. Sternchen o.&auml;.), oder pers&ouml;nlich mit.
          <br />
          Wir l&ouml;schen die Daten nach Ablauf gesetzlicher
          Gew&auml;hrleistungs- und vergleichbarer Pflichten, d.h.,
          grunds&auml;tzlich nach Ablauf von 4 Jahren, es sei denn, dass die
          Daten in einem Kundenkonto gespeichert werden, z.B., solange sie aus
          gesetzlichen Gr&uuml;nden der Archivierung aufbewahrt werden
          m&uuml;ssen (z.B. f&uuml;r Steuerzwecke im Regelfall 10 Jahre). Daten,
          die uns im Rahmen eines Auftrags durch den Vertragspartner offengelegt
          wurden, l&ouml;schen wir entsprechend den Vorgaben des Auftrags,
          grunds&auml;tzlich nach Ende des Auftrags.
          <br />
          Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder
          Plattformen einsetzen, gelten im Verh&auml;ltnis zwischen den Nutzern
          und den Anbietern die Gesch&auml;ftsbedingungen und
          Datenschutzhinweise der jeweiligen Drittanbieter oder Plattformen.
          <br />
          Veranstaltungen und Events: Wir verarbeiten die Daten der Teilnehmer
          der von uns angebotenen oder ausgerichteten Veranstaltungen, Events
          und &auml;hnlichen Aktivit&auml;ten (nachfolgend einheitlich als
          "Teilnehmer" und &bdquo;Veranstaltungen&ldquo; bezeichnet), um ihnen
          die Teilnahme an den Veranstaltungen und Inanspruchnahme der mit der
          Teilnahme verbundenen Leistungen oder Aktionen zu erm&ouml;glichen.{" "}
          <br />
          Sofern wir in diesem Rahmen gesundheitsbezogene Daten, religi&ouml;se,
          politische oder sonstige besondere Kategorien von Daten verarbeiten,
          dann erfolgt diese im Rahmen der Offenkundigkeit (z.B. bei thematisch
          ausgerichteten Veranstaltungen oder dient der Gesundheitsvorsorge,
          Sicherheit oder erfolgt mit Einwilligung der Betroffenen).
          <br />
          Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-,
          Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und
          umfassen die zur Leistungserbringung und Abrechnung ben&ouml;tigten
          Angaben sowie Kontaktinformationen, um etwaige R&uuml;cksprachen
          halten zu k&ouml;nnen. Soweit wir Zugang zu Informationen der
          Endkunden, Mitarbeitern oder anderer Personen erhalten, verarbeiten
          wir diese im Einklang mit den gesetzlichen und vertraglichen Vorgaben.
          <br />
          Weitere Informationen zu kommerziellen Dienstleistungen: Wir
          verarbeiten die Daten unserer Kunden sowie Auftraggeber (nachfolgend
          einheitlich als "Kunden" bezeichnet), um ihnen die Auswahl, den Erwerb
          bzw. die Beauftragung der gew&auml;hlten Leistungen oder Werke sowie
          verbundener T&auml;tigkeiten als auch deren Bezahlung und Zustellung
          bzw. Ausf&uuml;hrung oder Erbringung zu erm&ouml;glichen.
          <br />
          Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-,
          Bestell- bzw. vergleichbaren Vertragsschlusses gekennzeichnet und
          umfassen die zur Leistungserbringung und Abrechnung ben&ouml;tigten
          Angaben sowie Kontaktinformationen, um etwaige R&uuml;cksprachen
          halten zu k&ouml;nnen.
          <br />
          Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen),
          Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie),
          Kontaktdaten (z.B. E-Mail, Telefonnummern), Vertragsdaten (z.B.
          Vertragsgegenstand, Laufzeit, Kundenkategorie).
          <br />
          Betroffene Personen: Interessenten, Gesch&auml;fts- und
          Vertragspartner, Kunden.
          <br />
          Zwecke der Verarbeitung: Vertragliche Leistungen und Service,
          Kontaktanfragen und Kommunikation, B&uuml;ro- und
          Organisationsverfahren, Verwaltung und Beantwortung von Anfragen.
          <br />
          Rechtsgrundlagen: Vertragserf&uuml;llung und vorvertragliche Anfragen
          (Art. 6 Abs. 1 S. 1 lit. b. DSGVO), Rechtliche Verpflichtung (Art. 6
          Abs. 1 S. 1 lit. c. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1
          lit. f. DSGVO).
          <br />
          Bereitstellung des Onlineangebotes und Webhosting
          <br />
          Um unser Onlineangebot sicher und effizient bereitstellen zu
          k&ouml;nnen, nehmen wir die Leistungen von einem oder mehreren
          Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von ihnen
          verwalteten Servern) das Onlineangebot abgerufen werden kann. Zu
          diesen Zwecken k&ouml;nnen wir Infrastruktur- und
          Plattformdienstleistungen, Rechenkapazit&auml;t, Speicherplatz und
          Datenbankdienste sowie Sicherheitsleistungen und technische
          Wartungsleistungen in Anspruch nehmen.
          <br />
          Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten
          Daten k&ouml;nnen alle die Nutzer unseres Onlineangebotes betreffenden
          Angaben geh&ouml;ren, die im Rahmen der Nutzung und der Kommunikation
          anfallen. Hierzu geh&ouml;ren regelm&auml;&szlig;ig die IP-Adresse,
          die notwendig ist, um die Inhalte von Onlineangeboten an Browser
          ausliefern zu k&ouml;nnen, und alle innerhalb unseres Onlineangebotes
          oder von Webseiten get&auml;tigten Eingaben.
          <br />
          E-Mail-Versand und -Hosting: Die von uns in Anspruch genommenen
          Webhosting-Leistungen umfassen ebenfalls den Versand, den Empfang
          sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die
          Adressen der Empf&auml;nger sowie Absender als auch weitere
          Informationen betreffend den E-Mailversand (z.B. die beteiligten
          Provider) sowie die Inhalte der jeweiligen E-Mails verarbeitet. Die
          vorgenannten Daten k&ouml;nnen ferner zu Zwecken der Erkennung von
          SPAM verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails
          im Internet grunds&auml;tzlich nicht verschl&uuml;sselt versendet
          werden. Im Regelfall werden E-Mails zwar auf dem Transportweg
          verschl&uuml;sselt, aber (sofern kein sogenanntes
          Ende-zu-Ende-Verschl&uuml;sselungsverfahren eingesetzt wird) nicht auf
          den Servern, von denen sie abgesendet und empfangen werden. Wir
          k&ouml;nnen daher f&uuml;r den &Uuml;bertragungsweg der E-Mails
          zwischen dem Absender und dem Empfang auf unserem Server keine
          Verantwortung &uuml;bernehmen.
          <br />
          Erhebung von Zugriffsdaten und Logfiles: Wir selbst (bzw. unser
          Webhostinganbieter) erheben Daten zu jedem Zugriff auf den Server
          (sogenannte Serverlogfiles). Zu den Serverlogfiles k&ouml;nnen die
          Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
          Uhrzeit des Abrufs, &uuml;bertragene Datenmengen, Meldung &uuml;ber
          erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des
          Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall
          IP-Adressen und der anfragende Provider geh&ouml;ren.
          <br />
          Die Serverlogfiles k&ouml;nnen zum einen zu Zwecken der Sicherheit
          eingesetzt werden, z.B., um eine &Uuml;berlastung der Server zu
          vermeiden (insbesondere im Fall von missbr&auml;uchlichen Angriffen,
          sogenannten DDoS-Attacken) und zum anderen, um die Auslastung der
          Server und ihre Stabilit&auml;t sicherzustellen.
          <br />
          Verarbeitete Datenarten: Inhaltsdaten (z.B. Texteingaben, Fotografien,
          Videos), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
          Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
          Ger&auml;te-Informationen, IP-Adressen).
          <br />
          Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
          Onlinediensten).
          <br />
          Zwecke der Verarbeitung: Reichweitenmessung (z.B. Zugriffsstatistiken,
          Erkennung wiederkehrender Besucher), Tracking (z.B.
          interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies),
          Besuchsaktionsauswertung, Servermonitoring und Fehlererkennung.
          <br />
          Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
          DSGVO).
          <br />
          Eingesetzte Dienste und Diensteanbieter:
          <br />
          Squarespace: Squarespace bietet Software as a Service f&uuml;r die
          Erstellung und das Hosting von Websites an. Dienstanbieter:
          Squarespace, Inc. 8 Clarkson St, New York, NY 10014, USA; Website:
          https://www.squarespace.com; Datenschutzerkl&auml;rung:
          https://www.squarespace.com/privacy; Privacy Shield
          (Gew&auml;hrleistung Datenschutzniveau bei Verarbeitung von Daten in
          den USA):
          https://www.privacyshield.gov/participant?id=a2zt0000000GnjcAAC&amp;status=Active.
          <br />
          Onlinemarketing
          <br />
          Wir verarbeiten personenbezogene Daten zu Zwecken des
          Onlinemarketings, worunter insbesondere die Vermarktung von
          Werbefl&auml;chen oder Darstellung von werbenden und sonstigen
          Inhalten (zusammenfassend als "Inhalte" bezeichnet) anhand
          potentieller Interessen der Nutzer sowie die Messung ihrer
          Effektivit&auml;t fallen kann. <br />
          Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in
          einer Datei (sogenannte "Cookie") gespeichert oder &auml;hnliche
          Verfahren genutzt, mittels derer die f&uuml;r die Darstellung der
          vorgenannten Inhalte relevante Angaben zum Nutzer gespeichert werden.
          Zu diesen Angaben k&ouml;nnen z.B. betrachtete Inhalte, besuchte
          Webseiten, genutzte Onlinenetzwerke, aber auch Kommunikationspartner
          und technische Angaben, wie der verwendete Browser, das verwendete
          Computersystem sowie Angaben zu Nutzungszeiten geh&ouml;ren. Sofern
          Nutzer in die Erhebung ihrer Standortdaten eingewilligt haben,
          k&ouml;nnen auch diese verarbeitet werden.
          <br />
          Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch
          nutzen wir zur Verf&uuml;gung stehende IP-Masking-Verfahren (d.h.,
          Pseudonymisierung durch K&uuml;rzung der IP-Adresse) zum Schutz der
          Nutzer. Generell werden im Rahmen des Onlinemarketingverfahren keine
          Klardaten der Nutzer (wie z.B. E-Mail-Adressen oder Namen)
          gespeichert, sondern Pseudonyme. D.h., wir als auch die Anbieter der
          Onlinemarketingverfahren kennen nicht die tats&auml;chlich
          Identit&auml;t der Nutzer, sondern nur die in deren Profilen
          gespeicherten Angaben.
          <br />
          Die Angaben in den Profilen werden im Regelfall in den Cookies oder
          mittels &auml;hnlicher Verfahren gespeichert. Diese Cookies
          k&ouml;nnen sp&auml;ter generell auch auf anderen Webseiten die
          dasselbe Onlinemarketingverfahren einsetzen, ausgelesen und zu Zwecken
          der Darstellung von Inhalten analysiert als auch mit weiteren Daten
          erg&auml;nzt und auf dem Server des Onlinemarketingverfahrensanbieters
          gespeichert werden.
          <br />
          Ausnahmsweise k&ouml;nnen Klardaten den Profilen zugeordnet werden.
          Das ist der Fall, wenn die Nutzer z.B. Mitglieder eines sozialen
          Netzwerks sind, dessen Onlinemarketingverfahren wir einsetzen und das
          Netzwerk die Profile der Nutzer im den vorgenannten Angaben verbindet.
          Wir bitten darum, zu beachten, dass Nutzer mit den Anbietern
          zus&auml;tzliche Abreden, z.B. durch Einwilligung im Rahmen der
          Registrierung, treffen k&ouml;nnen.
          <br />
          Wir erhalten grunds&auml;tzlich nur Zugang zu zusammengefassten
          Informationen &uuml;ber den Erfolg unserer Werbeanzeigen. Jedoch
          k&ouml;nnen wir im Rahmen sogenannter Konversionsmessungen
          pr&uuml;fen, welche unserer Onlinemarketingverfahren zu einer
          sogenannten Konversion gef&uuml;hrt haben, d.h. z.B., zu einem
          Vertragsschluss mit uns. Die Konversionsmessung wird alleine zur
          Analyse des Erfolgs unserer Marketingma&szlig;nahmen verwendet.
          <br />
          Solange nicht anders angegeben, bitten wir Sie davon auszugehen, dass
          verwendete Cookies f&uuml;r einene Zeitraum von zwei Jahren
          gespeichert werden.
          <br />
          Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
          Einwilligung in den Einsatz der Drittanbieter bitten, ist die
          Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten
          werden die Daten der Nutzer auf Grundlage unserer berechtigten
          Interessen (d.h. Interesse an effizienten, wirtschaftlichen und
          empf&auml;ngerfreundlichen Leistungen) verarbeitet. In diesem
          Zusammenhang m&ouml;chten wir Sie auch auf die Informationen zur
          Verwendung von Cookies in dieser Datenschutzerkl&auml;rung hinweisen.
          <br />
          Facebook-Pixel: Mit Hilfe des Facebook-Pixels ist es Facebook zum
          einen m&ouml;glich, die Besucher unseres Onlineangebotes als
          Zielgruppe f&uuml;r die Darstellung von Anzeigen (sogenannte
          "Facebook-Ads") zu bestimmen. Dementsprechend setzen wir das
          Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur
          solchen Nutzern bei Facebook und innerhalb der Dienste der mit
          Facebook kooperierenden Partner (so genanntes "Audience Network"
          https://www.facebook.com/audiencenetwork/ ) anzuzeigen, die auch ein
          Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte
          Merkmale (z.B. Interesse an bestimmten Themen oder Produkten, die
          anhand der besuchten Webseiten ersichtlich werden) aufweisen, die wir
          an Facebook &uuml;bermitteln (sogenannte "Custom Audiences&ldquo;).
          Mit Hilfe des Facebook-Pixels m&ouml;chten wir auch sicherstellen,
          dass unsere Facebook-Ads dem potentiellen Interesse der Nutzer
          entsprechen und nicht bel&auml;stigend wirken. Mit Hilfe des
          Facebook-Pixels k&ouml;nnen wir ferner die Wirksamkeit der
          Facebook-Werbeanzeigen f&uuml;r statistische und Marktforschungszwecke
          nachvollziehen, indem wir sehen, ob Nutzer nach dem Klick auf eine
          Facebook-Werbeanzeige auf unsere Webseite weitergeleitet wurden
          (sogenannte "Konversionsmessung&ldquo;).
          <br />
          Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten,
          Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
          (z.B. Ger&auml;te-Informationen, IP-Adressen), Standortdaten (Daten,
          die den Standort des Endger&auml;ts eines Endnutzers angeben).
          <br />
          Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
          Onlinediensten), Interessenten.
          <br />
          Zwecke der Verarbeitung: Tracking (z.B.
          interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies),
          Remarketing, Besuchsaktionsauswertung, Interessenbasiertes und
          verhaltensbezogenes Marketing, Profiling (Erstellen von
          Nutzerprofilen), Konversionsmessung (Messung der Effektivit&auml;t von
          Marketingma&szlig;nahmen), Reichweitenmessung (z.B.
          Zugriffsstatistiken, Erkennung wiederkehrender Besucher),
          Zielgruppenbildung (Bestimmung von f&uuml;r Marketingzwecke relevanten
          Zielgruppen oder sonstige Ausgabe von Inhalten), Cross-Device Tracking
          (ger&auml;te&uuml;bergreifende Verarbeitung von Nutzerdaten f&uuml;r
          Marketingzwecke).
          <br />
          Sicherheitsma&szlig;nahmen: IP-Masking (Pseudonymisierung der
          IP-Adresse).
          <br />
          Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
          Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
          <br />
          Widerspruchsm&ouml;glichkeit (Opt-Out): Wir verweisen auf die
          Datenschutzhinweise der jeweiligen Anbieter und die zu den Anbietern
          angegebenen Widerspruchsm&ouml;glichkeiten (sog. "Opt-Out"). Sofern
          keine explizite Opt-Out-M&ouml;glichkeit angegeben wurde, besteht zum
          einen die M&ouml;glichkeit, dass Sie Cookies in den Einstellungen
          Ihres Browsers abschalten. Hierdurch k&ouml;nnen jedoch Funktionen
          unseres Onlineangebotes eingeschr&auml;nkt werden. Wir empfehlen daher
          zus&auml;tzlich die folgenden Opt-Out-M&ouml;glichkeiten, die
          zusammenfassend auf jeweilige Gebiete gerichtet angeboten werden: a)
          Europa: https://www.youronlinechoices.eu. b) Kanada:
          https://www.youradchoices.ca/choices. c) USA:
          https://www.aboutads.info/choices. d) Gebiets&uuml;bergreifend:
          http://optout.aboutads.info.
          <br />
          Eingesetzte Dienste und Diensteanbieter:
          <br />
          Google Analytics: Onlinemarketing und Webanalyse; Dienstanbieter:
          Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
          Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain
          View, CA 94043, USA; Website:
          https://marketingplatform.google.com/intl/de/about/analytics/;
          Datenschutzerkl&auml;rung: https://policies.google.com/privacy;
          Privacy Shield (Gew&auml;hrleistung Datenschutzniveau bei Verarbeitung
          von Daten in den USA):
          https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active;
          Widerspruchsm&ouml;glichkeit (Opt-Out): Opt-Out-Plugin:
          http://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen f&uuml;r
          die Darstellung von Werbeeinblendungen:
          https://adssettings.google.com/authenticated.
          <br />
          Facebook-Pixel: Facebook-Pixel; Dienstanbieter:
          https://www.facebook.com, Facebook Ireland Ltd., 4 Grand Canal Square,
          Grand Canal Harbour, Dublin 2, Irland, Mutterunternehmen: Facebook, 1
          Hacker Way, Menlo Park, CA 94025, USA; Website:
          https://www.facebook.com; Datenschutzerkl&auml;rung:
          https://www.facebook.com/about/privacy; Privacy Shield
          (Gew&auml;hrleistung Datenschutzniveau bei Verarbeitung von Daten in
          den USA):
          https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active;
          Widerspruchsm&ouml;glichkeit (Opt-Out):
          https://www.facebook.com/settings?tab=ads.
          <br />
          Pr&auml;senzen in sozialen Netzwerken
          <br />
          Wir unterhalten Onlinepr&auml;senzen innerhalb sozialer Netzwerke, um
          mit den dort aktiven Nutzern zu kommunizieren oder um dort
          Informationen &uuml;ber uns anzubieten.
          <br />
          Wir weisen darauf hin, dass dabei Daten der Nutzer au&szlig;erhalb des
          Raumes der Europ&auml;ischen Union verarbeitet werden k&ouml;nnen.
          Hierdurch k&ouml;nnen sich f&uuml;r die Nutzer Risiken ergeben, weil
          so z.B. die Durchsetzung der Rechte der Nutzer erschwert werden
          k&ouml;nnte. Im Hinblick auf US-Anbieter, die unter dem Privacy-Shield
          zertifiziert sind oder vergleichbare Garantien eines sicheren
          Datenschutzniveaus bieten, weisen wir darauf hin, dass sie sich damit
          verpflichten, die Datenschutzstandards der EU einzuhalten.
          <br />
          Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im
          Regelfall f&uuml;r Marktforschungs- und Werbezwecke verarbeitet. So
          k&ouml;nnen z.B. anhand des Nutzungsverhaltens und sich daraus
          ergebender Interessen der Nutzer Nutzungsprofile erstellt werden. Die
          Nutzungsprofile k&ouml;nnen wiederum verwendet werden, um z.B.
          Werbeanzeigen innerhalb und au&szlig;erhalb der Netzwerke zu schalten,
          die mutma&szlig;lich den Interessen der Nutzer entsprechen. Zu diesen
          Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer
          gespeichert, in denen das Nutzungsverhalten und die Interessen der
          Nutzer gespeichert werden. Ferner k&ouml;nnen in den Nutzungsprofilen
          auch Daten unabh&auml;ngig der von den Nutzern verwendeten Ger&auml;te
          gespeichert werden (insbesondere, wenn die Nutzer Mitglieder der
          jeweiligen Plattformen sind und bei diesen eingeloggt sind).
          <br />
          F&uuml;r eine detaillierte Darstellung der jeweiligen
          Verarbeitungsformen und der Widerspruchsm&ouml;glichkeiten (Opt-Out)
          verweisen wir auf die Datenschutzerkl&auml;rungen und Angaben der
          Betreiber der jeweiligen Netzwerke.
          <br />
          Auch im Fall von Auskunftsanfragen und der Geltendmachung von
          Betroffenenrechten weisen wir darauf hin, dass diese am effektivsten
          bei den Anbietern geltend gemacht werden k&ouml;nnen. Nur die Anbieter
          haben jeweils Zugriff auf die Daten der Nutzer und k&ouml;nnen direkt
          entsprechende Ma&szlig;nahmen ergreifen und Ausk&uuml;nfte geben.
          Sollten Sie dennoch Hilfe ben&ouml;tigen, dann k&ouml;nnen Sie sich an
          uns wenden.
          <br />
          Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen),
          Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
          Texteingaben, Fotografien, Videos), Nutzungsdaten (z.B. besuchte
          Webseiten, Interesse an Inhalten, Zugriffszeiten),
          Meta-/Kommunikationsdaten (z.B. Ger&auml;te-Informationen,
          IP-Adressen).
          <br />
          Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
          Onlinediensten).
          <br />
          Zwecke der Verarbeitung: Kontaktanfragen und Kommunikation, Tracking
          (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von
          Cookies), Remarketing, Reichweitenmessung (z.B. Zugriffsstatistiken,
          Erkennung wiederkehrender Besucher).
          <br />
          Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
          DSGVO).
          <br />
          Eingesetzte Dienste und Diensteanbieter:
          <br />
          Instagram : Soziales Netzwerk; Dienstanbieter: Instagram Inc., 1601
          Willow Road, Menlo Park, CA, 94025, USA; Website:
          https://www.instagram.com; Datenschutzerkl&auml;rung:
          http://instagram.com/about/legal/privacy.
          <br />
          Facebook: Soziales Netzwerk; Dienstanbieter: Facebook Ireland Ltd., 4
          Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland,
          Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA;
          Website: https://www.facebook.com; Datenschutzerkl&auml;rung:
          https://www.facebook.com/about/privacy; Privacy Shield
          (Gew&auml;hrleistung Datenschutzniveau bei Verarbeitung von Daten in
          den USA):
          https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active;
          Widerspruchsm&ouml;glichkeit (Opt-Out): Einstellungen f&uuml;r
          Werbeanzeigen: https://www.facebook.com/settings?tab=ads;
          Zus&auml;tzliche Hinweise zum Datenschutz: Vereinbarung &uuml;ber
          gemeinsame Verarbeitung personenbezogener Daten auf Facebook-Seiten:
          https://www.facebook.com/legal/terms/page_controller_addendum,
          Datenschutzhinweise f&uuml;r Facebook-Seiten:
          https://www.facebook.com/legal/terms/information_about_page_insights_data.
          <br />
          LinkedIn: Soziales Netzwerk; Dienstanbieter: LinkedIn Ireland
          Unlimited Company, Wilton Place, Dublin 2, Irland; Website:
          https://www.linkedin.com; Datenschutzerkl&auml;rung:
          https://www.linkedin.com/legal/privacy-policy; Privacy Shield
          (Gew&auml;hrleistung Datenschutzniveau bei Verarbeitung von Daten in
          den USA):
          https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active;
          Widerspruchsm&ouml;glichkeit (Opt-Out):
          https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.
          <br />
          YouTube: Soziales Netzwerk; Dienstanbieter: Google Ireland Limited,
          Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen:
          Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
          Datenschutzerkl&auml;rung: https://policies.google.com/privacy;
          Privacy Shield (Gew&auml;hrleistung Datenschutzniveau bei Verarbeitung
          von Daten in den USA):
          https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active;
          Widerspruchsm&ouml;glichkeit (Opt-Out):
          https://adssettings.google.com/authenticated.
          <br />
          Plugins und eingebettete Funktionen sowie Inhalte
          <br />
          Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein,
          die von den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet
          als "Drittanbieter&rdquo;) bezogen werden. Dabei kann es sich zum
          Beispiel um Grafiken, Videos oder Social-Media-Schaltfl&auml;chen
          sowie Beitr&auml;ge handeln (nachfolgend einheitlich bezeichnet als
          "Inhalte&rdquo;).
          <br />
          Die Einbindung setzt immer voraus, dass die Drittanbieter dieser
          Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die
          IP-Adresse die Inhalte nicht an deren Browser senden k&ouml;nnten. Die
          IP-Adresse ist damit f&uuml;r die Darstellung dieser Inhalte oder
          Funktionen erforderlich. Wir bem&uuml;hen uns, nur solche Inhalte zu
          verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur
          Auslieferung der Inhalte verwenden. Drittanbieter k&ouml;nnen ferner
          sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons"
          bezeichnet) f&uuml;r statistische oder Marketingzwecke verwenden.
          Durch die "Pixel-Tags" k&ouml;nnen Informationen, wie der
          Besucherverkehr auf den Seiten dieser Webseite, ausgewertet werden.
          Die pseudonymen Informationen k&ouml;nnen ferner in Cookies auf dem
          Ger&auml;t der Nutzer gespeichert werden und unter anderem technische
          Informationen zum Browser und zum Betriebssystem, zu verweisenden
          Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung unseres
          Onlineangebotes enthalten als auch mit solchen Informationen aus
          anderen Quellen verbunden werden.
          <br />
          Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
          Einwilligung in den Einsatz der Drittanbieter bitten, ist die
          Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten
          werden die Daten der Nutzer auf Grundlage unserer berechtigten
          Interessen (d.h. Interesse an effizienten, wirtschaftlichen und
          empf&auml;ngerfreundlichen Leistungen) verarbeitet. In diesem
          Zusammenhang m&ouml;chten wir Sie auch auf die Informationen zur
          Verwendung von Cookies in dieser Datenschutzerkl&auml;rung hinweisen.
          <br />
          Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten,
          Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten
          (z.B. Ger&auml;te-Informationen, IP-Adressen).
          <br />
          Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von
          Onlinediensten).
          <br />
          Zwecke der Verarbeitung: Bereitstellung unseres Onlineangebotes und
          Nutzerfreundlichkeit, Vertragliche Leistungen und Service.
          <br />
          Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f.
          DSGVO).
          <br />
          Eingesetzte Dienste und Diensteanbieter:
          <br />
          Google Maps: Wir binden die Landkarten des Dienstes &ldquo;Google
          Maps&rdquo; des Anbieters Google ein. Zu den verarbeiteten Daten
          k&ouml;nnen insbesondere IP-Adressen und Standortdaten der Nutzer
          geh&ouml;ren, die jedoch nicht ohne deren Einwilligung (im Regelfall
          im Rahmen der Einstellungen ihrer Mobilger&auml;te vollzogen), erhoben
          werden. Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
          Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
          https://maps.google.de; Datenschutzerkl&auml;rung:
          https://policies.google.com/privacy; Privacy Shield
          (Gew&auml;hrleistung Datenschutzniveau bei Verarbeitung von Daten in
          den USA):
          https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&amp;status=Active;
          Widerspruchsm&ouml;glichkeit (Opt-Out): Opt-Out-Plugin:
          http://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen f&uuml;r
          die Darstellung von Werbeeinblendungen:
          https://adssettings.google.com/authenticated.
          <br />
          L&ouml;schung von Daten
          <br />
          Die von uns verarbeiteten Daten werden nach Ma&szlig;gabe der
          gesetzlichen Vorgaben gel&ouml;scht, sobald deren zur Verarbeitung
          erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse
          entfallen (z.B., wenn der Zweck der Verarbeitung dieser Daten
          entfallen ist oder sie f&uuml;r den Zweck nicht erforderlich sind).
          <br />
          Sofern die Daten nicht gel&ouml;scht werden, weil sie f&uuml;r andere
          und gesetzlich zul&auml;ssige Zwecke erforderlich sind, wird deren
          Verarbeitung auf diese Zwecke beschr&auml;nkt. D.h., die Daten werden
          gesperrt und nicht f&uuml;r andere Zwecke verarbeitet. Das gilt z.B.
          f&uuml;r Daten, die aus handels- oder steuerrechtlichen Gr&uuml;nden
          aufbewahrt werden m&uuml;ssen oder deren Speicherung zur
          Geltendmachung, Aus&uuml;bung oder Verteidigung von
          Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
          nat&uuml;rlichen oder juristischen Person erforderlich ist.
          <br />
          Weitere Hinweise zu der L&ouml;schung von personenbezogenen Daten
          k&ouml;nnen ferner im Rahmen der einzelnen Datenschutzhinweise dieser
          Datenschutzerkl&auml;rung erfolgen.
          <br />
          <br />
          <strong>
            &Auml;nderung und Aktualisierung der Datenschutzerkl&auml;rung:
          </strong>
          <br />
          Wir bitten Sie, sich regelm&auml;&szlig;ig &uuml;ber den Inhalt
          unserer Datenschutzerkl&auml;rung zu informieren. Wir passen die
          Datenschutzerkl&auml;rung an, sobald die &Auml;nderungen der von uns
          durchgef&uuml;hrten Datenverarbeitungen dies erforderlich machen. Wir
          informieren Sie, sobald durch die &Auml;nderungen eine
          Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige
          individuelle Benachrichtigung erforderlich wird.
          <br />
          Sofern wir in dieser Datenschutzerkl&auml;rung Adressen und
          Kontaktinformationen von Unternehmen und Organisationen angeben,
          bitten wir zu beachten, dass die Adressen sich &uuml;ber die Zeit
          &auml;ndern k&ouml;nnen und bitten die Angaben vor Kontaktaufnahme zu
          pr&uuml;fen.
          <br />
          Rechte der betroffenen Personen
          <br />
          Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die
          sich insbesondere aus Art. 15 bis 18 und 21 DSGVO ergeben:
          <br />
          Widerspruchsrecht: Sie haben das Recht, aus Gr&uuml;nden, die sich aus
          Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
          der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6
          Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt
          auch f&uuml;r ein auf diese Bestimmungen gest&uuml;tztes Profiling.
          Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
          Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch
          gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten
          zum Zwecke derartiger Werbung einzulegen; dies gilt auch f&uuml;r das
          Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
          <br />
          Widerrufsrecht bei Einwilligungen: Sie haben das Recht, erteilte
          Einwilligungen jederzeit zu widerrufen.
          <br />
          Auskunftsrecht: Sie haben das Recht, eine Best&auml;tigung
          dar&uuml;ber zu verlangen, ob betreffende Daten verarbeitet werden und
          auf Auskunft &uuml;ber diese Daten sowie auf weitere Informationen und
          Kopie der Daten entsprechend den gesetzlichen Vorgaben.
          <br />
          Recht auf Berichtigung: Sie haben entsprechend den gesetzlichen
          Vorgaben das Recht, die Vervollst&auml;ndigung der Sie betreffenden
          Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu
          verlangen.
          <br />
          Recht auf L&ouml;schung und Einschr&auml;nkung der Verarbeitung: Sie
          haben nach Ma&szlig;gabe der gesetzlichen Vorgaben das Recht, zu
          verlangen, dass Sie betreffende Daten unverz&uuml;glich gel&ouml;scht
          werden, bzw. alternativ nach Ma&szlig;gabe der gesetzlichen Vorgaben
          eine Einschr&auml;nkung der Verarbeitung der Daten zu verlangen.
          <br />
          Recht auf Daten&uuml;bertragbarkeit: Sie haben das Recht, Sie
          betreffende Daten, die Sie uns bereitgestellt haben, nach
          Ma&szlig;gabe der gesetzlichen Vorgaben in einem strukturierten,
          g&auml;ngigen und maschinenlesbaren Format zu erhalten oder deren
          &Uuml;bermittlung an einen anderen Verantwortlichen zu fordern.
          <br />
          Beschwerde bei Aufsichtsbeh&ouml;rde: Sie haben ferner nach
          Ma&szlig;gabe der gesetzlichen Vorgaben das Recht, bei einer
          Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat Ihres
          gew&ouml;hnlichen Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts
          des mutma&szlig;lichen Versto&szlig;es, wenn Sie der Ansicht sind,
          dass die Verarbeitung der Sie betreffenden personenbezogenen Daten
          gegen die DSGVO verst&ouml;&szlig;t.
        </p>
        <p>
          <br />
          Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
          Schwenke
        </p>
      </div>
    </div>
  )
}

export default Privacy
